import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 style={{
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '5rem',
      color: 'gray',
      userSelect: 'none',
      fontWeight: '500',
    }}>
      bossa<b style={{ color: 'red'}}>ever</b>nova
    </h1>
  </Layout>
)

export default IndexPage
